
import { Component, Vue } from 'vue-property-decorator'
import UploadFile from '@/components/uploadFile/Index.vue'
import { Info } from '@/types/expert'
import { FileInfo, Tag } from '@/types/common'
import { ElForm } from 'element-ui/types/form'

@Component({
  components: { UploadFile }
})
export default class ExpertAdd extends Vue {
  private info: Info = {
    expertName: '',
    positionName: '',
    expertIntroduce: '',
    honor: '',
    isShow: 1,
    tagList: [],
    resource: null
  }

  private rules = {
    expertName: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
    positionName: [{ required: true, message: '请输入职位名称', trigger: ['blur', 'change'] }],
    resource: [{ required: true, message: '请上传头像', trigger: ['blur', 'change'] }],
    tagList: [{ required: true, message: '请添加或选择擅长领域', trigger: ['blur', 'change'] }],
    expertIntroduce: [{ required: true, message: '请输入专家介绍', trigger: ['blur', 'change'] }]
  }

  private tagList: Array<Tag> = []
  private tagName = ''
  private addShow = false
  private submitShow = false

  private get title () {
    return this.$route.params.id ? '编辑' : '新增'
  }

  created () {
    this.getTagList()
    this.$route.params.id && this.getDetail()
  }

  getDetail () {
    this.$axios.get(this.$apis.expert.expertDetail, {
      expertId: this.$route.params.id
    }).then(res => {
      this.info = res
    })
  }

  getTagList () {
    this.$axios.get(this.$apis.common.thinktankTag).then(res => {
      this.tagList = res.list
    })
  }

  querySearch (queryString: string, cb: Function) {
    const tagList = this.tagList
    const results = queryString ? tagList.filter(this.createFilter(queryString)) : tagList
    this.addShow = results.length === 0
    // 调用 callback 返回建议列表的数据
    cb(results)
  }

  createFilter (queryString: string) {
    return (tag: Tag) => {
      return (tag.tagName.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
    }
  }

  // 标签选中
  handleSelect (v: Tag) {
    (this.info.tagList as Array<Tag>).push(v)
  }

  // 标签删除
  removeTag (index: number) {
    this.info.tagList.splice(index, 1)
  }

  // 自定义标签添加
  addTag () {
    const index = this.info.tagList.findIndex((item: Tag) => {
      return item.tagName === this.tagName
    })
    if (index === -1) {
      (this.info.tagList as Array<Tag>).push({
        tagId: null,
        tagName: this.tagName
      })
      this.tagName = ''
      this.addShow = false
    } else {
      this.$message({
        message: '标签重复',
        type: 'warning'
      })
    }
  }

  onSuccess (file: FileInfo) {
    this.info.resource = file
  }

  onSubmit () {
    (this.$refs.info as ElForm).validate((valid) => {
      if (valid) {
        this.submitShow = true
        const url = this.$route.params.id
          ? this.$apis.expert.expertUpdate
          : this.$apis.expert.expertAdd
        this.$axios.post(url, this.info).then(() => {
          this.$message({
            message: '保存成功',
            type: 'success'
          })
          this.$router.push({ name: 'expertList' })
        }).finally(() => {
          this.submitShow = false
        })
      }
    })
  }
}
